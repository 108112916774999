.link-button,
.button,
.button--secondary {
    border-radius: 5px;
    box-shadow: $mwl-red inset 0px 0px 0px 3px;
    display: inline-block;
    text-decoration: none;
    color: $mwl-red;
    font-family: $bold-font;
    font-size: 1.8rem;
    padding: 1.4rem 1.7rem;
    transition:
        box-shadow 0.2s,
        transform 0.2s;

    &:hover {
        box-shadow: $mwl-red inset 0px 0px 0px 5px;
        transform: scale(1.1);
        cursor: pointer;
    }

    &.is-loading {
        position: relative;
        cursor: wait;

        &::before {
            content: "";
            color: inherit;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: inherit;
            border-radius: inherit;
        }

        .loader {
            width: 2.5rem;
            height: 2.5rem;
            display: inline-block;
            position: absolute;
            color: #fff;
            border: 1px solid;
            box-sizing: border-box;
            top: 50%;
            left: 50%;
            overflow: hidden;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        .loader::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 20%;
            width: 200%;
            height: 20%;
            background-color: #fff;
            transform: rotate(-45deg);
            transform-origin: bottom;
            animation: fill 2s linear infinite alternate;
        }

        @keyframes fill {
            100% {
                height: 170%;
            }
        }
    }
}

.button {
    color: #ffffff;
    background-color: $mwl-red;
    border: none;
    padding: 1.4rem 5.5rem;
}

.link {
    position: relative;
    background-image: linear-gradient($mwl-red, $mwl-red);
    background-position: 0 calc(100% + 4px);
    background-repeat: no-repeat;
    background-size: 100% 0px;
    transition: background-size 0.2s;

    padding-bottom: 5px;

    &:hover {
        background-size: 100% 8px;
    }
}

.button--secondary {
    background-color: #fff;
    border: none;
    padding: 1.4rem 5.5rem;

    @media only screen and (max-width: $tablet) {
        margin-top: 3.2rem;
    }
}

.link-button__adaptive {
    @media only screen and (max-width: $small) {
        display: none;
    }
}
