.tester-container {
    max-width: 110rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 1240px) {
        margin: 0 4.8rem;
        max-width: 100%;
        width: auto;
    }

    // @media only screen and (max-width: $desktop) {
    //     margin: 0 4.8rem;
    // }

    @media only screen and (max-width: $tablet) {
        margin: 0 3.2rem;
    }
}

.title {
    font-size: 6.4rem;
    font-family: $bold-font;
    margin: 6.4rem 0;
    text-align: center;
}

.form-container {
    width: 100%;
    margin-bottom: 6.4rem;
}

.advanced-fields-container {
    padding-top: 3.2rem;
    width: 100%;
}

.advanced-fields-toggle {
    display: inline-block;

    color: $mwl-red;
    font-family: $bold-font;
    margin-right: 4.8rem;
    padding: 1.5rem 0;
    transition: transform 0.2s;

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
}

.glyphicon::before {
    content: "";
    display: inline-block;
    border-style: solid;
    border-width: 0.3rem 0.3rem 0 0;

    height: 1.2rem;
    width: 1.2rem;

    left: 1.6rem;
    position: relative;
    top: 0;
    transform: rotate(135deg);

    vertical-align: top;
}

.expanded {
    .glyphicon::before {
        transform: rotate(-45deg);
        top: 0.6rem;
    }
}
