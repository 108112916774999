.footer {
    background-color: #000;
    color: #fff;
    padding: 8.4rem 6.4rem 3rem;
    font-size: 2rem;
    line-height: 170%;
    margin-top: 6.4rem;

    a {
        color: #fff;
        text-decoration: none;
    }

    .footer-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        max-width: 96rem;
        margin: 0 auto 10rem;

        @media only screen and (max-width: 1000px) {
            flex-direction: column-reverse;
        }
    }

    h3 {
        color: $dark-grey;
        margin-bottom: 4.2rem;
        font-size: 2.4rem;
        font-family: $bold-font;
    }

    &__links {
        display: flex;
        flex-direction: column;

        a {
            margin-bottom: 2rem;
        }
    }

    &__contribute {
        max-width: 56rem;
        font-size: 1.4rem;
        line-height: 170%;

        @media only screen and (max-width: $tablet) {
            font-size: 1.6rem;
        }

        @media only screen and (max-width: 1000px) {
            max-width: 100%;
            margin-bottom: 6.4rem;
        }

        .disclaimer {
            &__bold {
                font-family: $bold-font;
                white-space: nowrap;
            }

            &-link {
                color: $mwl-red;
            }

            @media only screen and (max-width: $tablet) {
                font-size: 1.6rem;
            }
        }
    }

    &__copyright {
        text-align: center;
        font-size: 1.5rem;
        margin: 0;
        color: $dark-grey;

        a {
            color: $dark-grey;
            font-family: $bold-font;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        .strong {
            font-family: $bold-font;
        }
    }

    .footer-tweet-container {
        display: none;
        @media only screen and (max-width: $small) {
            display: inline-block;
            width: 100%;
            text-align: center;
        }
        .footer-tweet-button {
            width: 100%;
            margin: 1.6rem 0 6.4rem;
        }
    }
}
