@font-face {
    font-family: "Mark Pro Heavy";
    src:
        url("../../fonts/MarkPro-Heavy.woff2") format("woff2"),
        url("../../fonts/MarkPro-Heavy.woff") format("woff");
}

@font-face {
    font-family: "Mark Pro Book";
    src:
        url("../../fonts/MarkPro-Book.woff2") format("woff2"),
        url("../../fonts/MarkPro-Book.woff") format("woff");
}

$mwl-red: #ed2324;
$valid-rule-color: #11b20e;
$invalid-rule-color: #ffa800;
$placeholder-color: #a6afbf;
$not-processed-color: #a6afbf;
$not-supported-color: #a6afbf;

$dark-grey: #818181;
$input-color: #303030;

$default-font: "Mark Pro Book", Helvetica, Arial, sans-serif;
$bold-font: "Mark Pro Heavy";

$code-font: "Roboto Mono", monospace;

$desktop: 1200px;
$tablet: 768px;
$small: 600px;
