.docs-container {
    max-width: 110rem;
    width: 100%;
    margin: 0 auto;

    @media only screen and (max-width: 1240px) {
        margin: 0 12.8rem;
        max-width: 100%;
        width: auto;
    }

    @media only screen and (max-width: $desktop) {
        margin: 0 6.4rem;
    }

    @media only screen and (max-width: $tablet) {
        margin: 0 3.2rem;
    }
}

.title-lead-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 60rem;
    margin: 0 auto;
    margin-bottom: 10rem;
}

.lead {
    font-size: 2.4rem;
    line-height: 170%;
    text-align: center;
}

.sub-title {
    font-family: $bold-font;
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
    margin-left: 3rem;
}

.post,
.post-p {
    font-family: $bold-font;
    margin-left: 3rem;
}

.post {
    font-size: 3.6rem;
    margin-bottom: 12rem;
    color: $mwl-red;

    @media only screen and (max-width: $tablet) {
        font-size: 2.8rem;
    }

    @media only screen and (max-width: $small) {
        word-wrap: break-word;
    }
}

.post-p {
    font-size: 2.8rem;
    margin-bottom: 8rem;

    @media only screen and (max-width: $tablet) {
        font-size: 2.4rem;
    }
}

.code-container {
    box-shadow: 6px 5px 20px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    line-height: 170%;
    overflow-x: scroll;
}

.first-code-container {
    margin-bottom: 6.4rem;
}
