.tester-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error {
    background-color: $mwl-red;
    color: white;
    width: 100%;
    padding: 20px;
    margin-bottom: 3rem;
    box-shadow: 6px 5px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    line-height: 2.5rem;

    svg {
        fill: #fff;
        margin-right: 16px;
        position: relative;
        top: 3px;
    }
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.2rem;
    width: 100%;

    label {
        margin-left: 3rem;
        margin-bottom: 1.6rem;
    }

    .input-container {
        box-shadow: 6px 5px 20px rgba(0, 0, 0, 0.1);
        padding: 1.8rem 3rem;
    }

    input,
    textarea {
        border: none;
        border-bottom: 2px solid $mwl-red;
        font-size: 1.6rem;
        width: 100%;
        padding-bottom: 0.6rem;
        font-family: $code-font;
        color: $input-color;
        line-height: 140%;
        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $placeholder-color;
            line-height: 150%;
        }

        &:disabled {
            color: rgba($input-color, 0.3);
            border-bottom-color: rgba($mwl-red, 0.3);
        }
    }
}

.server-variable {
    display: flex;
    flex-direction: row;
    .input-container {
        flex: 1;
    }
    label {
        flex: 1;
        margin-left: 3rem;
        margin-bottom: 1.6rem;
    }
    .remove-variable {
        margin-left: 2rem;
        display: inline-block;

        color: $mwl-red;
        font-family: $bold-font;
        padding: 2rem 0;
        transition: transform 0.2s;

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }
    }
}

textarea {
    resize: vertical;
    min-height: 12.4rem;
}
