@import "base/reset";
@import "utils/variables";
@import "base/typography";
@import "base/form";
@import "layout/header";
@import "layout/footer";
@import "layout/results";
@import "layout/tester";
@import "base/button";
@import "layout/docs";
@import "layout/newsletter";

*,
:after,
:before {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    font-family: sans-serif;
}

body {
    font-size: 1.6rem;
}

.sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
