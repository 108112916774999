.debug-share-container {
    margin: 0 3rem;
    display: flex;
    justify-content: space-between;

    .share-container {
        display: flex;
        align-items: center;
        p {
            font-size: 1.4rem;
            @media only screen and (max-width: 1000px) {
                display: none;
            }
        }

        button {
            @media only screen and (max-width: 1000px) {
                padding: 1.4rem;
            }

            @media only screen and (max-width: $small) {
                width: 100%;
            }
        }

        span {
            @media only screen and (max-width: $small) {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: $tablet) {
        flex-direction: column;
    }
}

.results {
    margin-top: 6.4rem;
}

code {
    font-family: $code-font;
}

.results-container {
    width: 100%;
}

.output-url-label {
    font-family: $bold-font;
    font-size: 3.6rem;
}

.debug-title {
    font-size: 4.8rem;
    font-family: $bold-font;
}

.line {
    word-break: break-all;
    padding: 1.8rem 0;
    line-height: 170%;

    .line-flex-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        align-items: first baseline;
        margin-bottom: 0.8rem;
    }

    .line-value {
        flex: 1;
    }

    .line-number,
    .line-value {
        color: $placeholder-color;
    }

    .line-message {
        margin-left: 5.6rem;
    }

    .sub-run-result {
        margin-left: 5.6rem;
    }

    .line-number {
        margin-right: 2.4rem;
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:not(:last-child) {
        border-bottom: 0.1rem solid #a6afbf;
    }
}

.line-number {
    font-size: 1.3rem;
}

.rule-was-met {
    .line-number {
        background-color: $valid-rule-color;
        border-radius: 50%;
        color: #fff;
    }
    .line-value {
        color: $valid-rule-color;
    }
}

.rule-was-not-met {
    .line-number {
        background-color: $mwl-red;
        border-radius: 50%;
        color: #fff;
    }

    .line-value {
        color: $mwl-red;
    }
}

.rule-invalid {
    .line-number {
        width: 3.2rem;
        height: 3.2rem;
        background-color: $invalid-rule-color;
        border-radius: 50%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .line-value {
        color: $invalid-rule-color;
    }
}

.rule-not-supported {
    .line-number {
        color: $not-supported-color;
        background: none;
    }

    .line-value {
        color: $not-supported-color;
    }
}

.rule-not-processed {
    .line-number {
        background-color: $not-processed-color;
        border-radius: 50%;
        color: #fff;
    }
    .line-value {
        color: $not-processed-color;
    }
}

.output-url {
    margin-bottom: 4.8rem;
}
