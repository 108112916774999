.newsletter-signup {
    background-color: #f0f0f0;
    padding: 6.4rem 0;

    & + footer {
        margin-top: 0;
    }

    .title {
        font-size: 4rem;
        margin-top: 0;
        margin-bottom: 2rem;
    }

    .subtitle {
        text-align: center;
        font-size: 2rem;
        font-weight: bolder;
        margin-bottom: 6.4rem;
    }

    .input-container {
        background-color: #fff;

        .help {
            margin-top: 1rem;
        }
    }
}

.newsletter-signup-container {
    max-width: 110rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 1240px) {
        margin: 0 4.8rem;
        max-width: 100%;
        width: auto;
    }

    @media only screen and (max-width: $tablet) {
        margin: 0 3.2rem;
    }
}

.newsletter-signup-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .form-group {
        max-width: 600px;
    }

    .form-error {
        color: $mwl-red;
    }
}
