.header {
    max-width: 140rem;
    width: 100%;
    margin: 0 auto;
    padding: 3.6rem 0;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    @media only screen and (max-width: 1480px) {
        margin: 0 4rem;
        max-width: 100%;
        width: auto;
    }

    .logo,
    .tweet-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .logo-title {
        font-size: 2rem;
        color: $mwl-red;
        font-family: $bold-font;
    }

    .logo {
        text-decoration: none;

        .logo-wrapper {
            animation: pounding 4s linear infinite;
            &:hover {
                animation-duration: 1.2s;
            }

            svg {
                margin-right: 1.4rem;
                transition: transform 0.3s;
            }
        }

        &:hover {
            .logo-wrapper {
                svg {
                    transform: scale(1.2);
                }
            }
        }
    }

    .tweet-container p {
        font-size: 1.4rem;

        @media only screen and (max-width: $tablet) {
            display: none;
        }
    }
}

.margin-left {
    margin-left: 2.4rem;

    @media only screen and (max-width: $tablet) {
        margin-left: 0;
    }
}

@keyframes pounding {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.05);
    }
    40% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}
